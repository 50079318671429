<template>
	<div class="zl_pc_part">
		<div class="banner-herder padding_120">
			<div class="banner-box">
				<!--    顶部轮播      -->
				<TopSwiper type="2"> </TopSwiper>
			</div>
		</div>
		<div class="flex justify_content_sb padding_120 content-box">
			<div class="MainLeft MainLeft_760 MainLeft_right_30">
				<div>
					<section
						class="js-FeaturedColumn_container swiper-no-swiping swiper-container-initialized swiper-container-horizontal"
					>
						<div
							class="js-FeaturedColumn_title"
							v-if="popularColumn.length > 0"
						>
							<div
								class="js-FeaturedColumn_title-content justify_content_sb"
								style="width: 100%"
							>
								<p class="js-FeaturedColumn_title-info">精选专栏</p>
								<div
									class="js-FeaturedColumn_title-more"
									@click="$router.push({ path: '/zl_list' })"
								>
									<div class="rsTitleLeft_point"></div>
									<div class="rsTitleLeft_point"></div>
									<div class="rsTitleLeft_point"></div>
								</div>
							</div>
						</div>
						<div
							class="js-FeaturedColumn_Datalist"
							style="transform: translate3d(0px, 0px, 0px)"
							v-if="popularColumn.length > 0"
						>
							<div
								class="js-FeaturedColumn_DataItem swiper-slide-active"
								style="width: 100%"
							>
								<!-- 这里是作者信息top部分-轮播图 -->
								<el-carousel
									:interval="0"
									arrow="always"
									indicator-position="none"
								>
									<el-carousel-item
										v-for="(items, popularIndex) in popularColumn"
										:key="popularIndex + 'po'"
										><ul class="js-FeaturedColumn_list">
											<li
												v-for="(item, popularColumnIndex) in items"
												:key="popularColumnIndex + 'pop'"
												class="js-FeaturedColumn_item pointer"
												@mouseover="
													onMouseEnter(popularIndex, popularColumnIndex)
												"
											>
												<a>
													<img
														v-if="
															item.userInfo != null &&
															item.userInfo.avatar_img != '' &&
															item.userInfo.avatar_img != null
														"
														@click="
															$router.push({
																name: '/home_wz',
																params: { id: item.userInfo.id },
															})
														"
														:src="item.userInfo.avatar_img"
														:title="item.userInfo.nickname"
														alt="avatar"
														class="js-FeaturedColumn_item-avatar"
														style="object-fit: cover"
													/>
													<img
														class="js-FeaturedColumn_item-avatar"
														src="https://res.metaera.hk/resources/user_header.png"
														alt=""
														v-else
													/>
													<img
														src="https://res.metaera.hk/resources/assets/images/home/vip.png"
														class="js-FeaturedColumn_item-authentication"
													/>
													<p
														class="js-FeaturedColumn_item-nickname"
														v-if="
															item.userInfo != null &&
															item.userInfo.nickname != '' &&
															item.userInfo.nickname != null
														"
													>
														{{ item.userInfo.nickname }}</p
													>
													<p class="js-FeaturedColumn_item-nickname" v-else>
														MetaEra用户</p
													>
													<p class="js-FeaturedColumn_item-articles">
														文章：{{ item.information_num }}篇</p
													>
												</a>
												<div style="">
													<div
														@click="followAuthorFun(item.userInfo.id, 0)"
														class="js-FeaturedColumn_item-followed"
														v-if="item.is_follow == 1"
													>
													</div>
													<div
														@click="followAuthorFun(item.userInfo.id, 1)"
														class="followMeta flex justify_content_c align_items_c"
														v-else
													>
														+ 关注
													</div>
												</div>
												<div
													class="zl_hotCurrent_current"
													v-show="
														hotCurrentIndexLeft ==
														popularIndex + '-' + popularColumnIndex
													"
												></div>
											</li>
										</ul>
									</el-carousel-item>
								</el-carousel>
								<!-- <div class="zl_hotCurrent_line"> </div> -->
								<ul class="zl_author_articles_main">
									<div
										class="zl_author_articles_item"
										v-for="(item, infoListsIndex) in infoLists"
										:key="infoListsIndex + 'info'"
										v-show="infoListsIndex < 4"
									>
										<router-link
											target="_blank"
											:to="{ name: '/gw_detail', params: { id: item.id } }"
										>
											<div class="flex title-box">
												<div class="zl_author_articles_item_point"> </div>
												<div
													class="zl_author_articles_item_title pointer hovercolor"
												>
													{{
														localLanguage == 2
															? item.title
																? item.title
																: item.title
															: item.title
													}}
												</div>
											</div>
											<div class="zl_author_articles_item_time">
												{{ item.release_time }}
											</div>
										</router-link>
									</div>
								</ul>
							</div>
						</div>
						<span
							class="swiper-notification"
							aria-live="assertive"
							aria-atomic="true"
						></span>
					</section>
				</div>
				<div class="tageBox tageBoxZL">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane
							:label="
								localLanguage == 2
									? item.name_en
										? item.name_en
										: item.name
									: item.name
							"
							:name="item.id + ''"
							v-for="(item, categoryListsIndex) in categoryLists"
							:key="categoryListsIndex + 'cat'"
						>
							<div v-if="articleList.length > 0" class="wzitem_main">
								<div
									class="zl_articleList_pc align_items_c pointer"
									v-for="(items, articleListIndexs) in articleList"
									:key="articleListIndexs + 'art'"
								>
									<router-link
										target="_blank"
										:to="{ name: '/gw_detail', params: { id: items.id } }"
									>
										<wzitem :item="items"></wzitem>
									</router-link>
								</div>
								<div
									class="zl_articleList_app"
									v-for="(items, articleListIndexs1) in articleList"
									:key="articleListIndexs1 + 'arti'"
								>
									<router-link
										target="_blank"
										:to="{ name: '/gw_detail', params: { id: items.id } }"
									>
										<PerNews :item="items"></PerNews>
									</router-link>
								</div>
								<scrollToBottom @reachBottom="getDataListPage"></scrollToBottom>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<div class="MainRight MainRight_400">
				<module-part type="1"> </module-part>
				<module-part type="4"> </module-part>
				<module-part type="5"> </module-part>
				<download></download>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getPopularColumn,
	getArticleList,
	categoryLists,
	getZLHostLists,
	getWeekAuthorList,
} from "@/api/home";
import { followAuthor } from "@/api/user";
import { getToken } from "@/utils/auth";
import PerNews from "../home/mobile/perNews";
import wzitem from "./../../components/pc/wzitem.vue";
import download from "./../../components/pc/download.vue";
import hotArticle from "../home/hotArticle.vue";
import authorList from "../../components/pc/authorList.vue";
import { newBus } from "../../components/pc/topHeader2.vue";
import modulePart from "../../components/modulePart.vue";
import TopSwiper from "@/components/topSwiper";
export default {
	name: "",
	components: {
		PerNews,
		wzitem,
		download,
		hotArticle,
		authorList,
		modulePart,
		TopSwiper,
	},
	data() {
		return {
			activeName: "0",
			articleList: [],
			categoryLists: [],
			popularColumn: [],
			infoLists: [],
			noticeLists: [],
			authorLists: [],
			page: 1,
			size: 25,
			total: "",
			loading: false,
			hotLists: [],
			hotCurrentIndex: 1,
			hotCurrentIndexLeft: "0-0",
			localLanguage: 1,
		};
	},
	methods: {
		showUrl(url) {
			window.open(url, "");
		},
		gotoMy() {
			const hasToken = getToken();
			// console.log(hasToken);
			if (hasToken) {
				this.$router.push({ path: "/publish", query: {} });
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		onMouseEnter(index, ind) {
			this.hotCurrentIndexLeft = `${index}-${ind}`;
			this.infoLists = this.popularColumn[index][ind].infoList;
		},
		onMouseHotEnter(index) {
			this.hotCurrentIndex = index;
		},
		// 关注
		followAuthorFun(id, is_status) {
			const hasToken = getToken();
			// console.log(hasToken);
			if (hasToken) {
				followAuthor({ author_id: id, is_status: is_status })
					.then((res) => {
						if (res.code == 200) {
							this.$message.success("操作成功！");
							this.getPopularColumn();
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		getPopularColumn() {
			getPopularColumn({ type: 5, size: 12 })
				.then((res) => {
					if (res.code == 200) {
						const list = res.data.list;
						this.popularColumn = [];
						console.log("list", list);
						if (list && list.length > 0) {
							// 有数据
							for (var i = 0; i < list.length; i = i + 6) {
								var item = list.slice(i, i + 6);
								this.popularColumn.push(item);
							}
						}
						if (this.popularColumn.length > 0) {
							this.infoLists = this.popularColumn[0][0].infoList;
						} else {
							this.infoLists = [];
						}
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getWeekAuthorList() {
			getWeekAuthorList({ type: 5 })
				.then((res) => {
					if (res.code == 200) {
						if (res.data.list.length > 5) {
							this.authorLists = res.data.list.slice(0, 5);
						} else {
							this.authorLists = res.data.list;
						}
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		handleClick(val) {
			this.page = 1;
			this.articleList = [];
			this.getArticleList(
				{ category_id: val.name, type: 5, page: this.page, size: this.size },
				0
			);
		},
		getDataListPage() {
			if (this.loading) {
				return;
			}
			if (this.page >= Math.ceil(this.total / this.size)) {
				return;
			}
			this.loading = true;
			this.page = this.page + 1;
			this.getArticleList(
				{
					category_id: this.activeName,
					type: 5,
					page: this.page,
					size: this.size,
				},
				0
			);
		},
		getArticleList(obj, type) {
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						if (obj.page == 1) {
							this.articleList = res.data.list;
						} else {
							this.articleList = this.articleList.concat(res.data.list);
						}
						this.total = res.data.total;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getCategortLists() {
			categoryLists({ type: 5 })
				.then((res) => {
					if (res.code == 200) {
						this.categoryLists = res.data;

						var cate = { id: 0, name: "全部", name_en: "All" };
						this.categoryLists.unshift(cate);

						if (res.data.length > 0) {
							this.activeName = res.data[0].id + "";
							this.getArticleList(
								{
									category_id: this.activeName,
									type: 5,
									page: this.page,
									size: this.size,
								},
								0
							);
						}
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getZLHostLists() {
			getZLHostLists({ type: 10, page: this.page, size: this.size })
				.then((res) => {
					if (res.code == 200) {
						this.hotLists = res.data.list;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	created() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
		this.getPopularColumn();
		this.getCategortLists();
		this.getZLHostLists();
		this.getWeekAuthorList();
	},
};
</script>

<style>
.followMeta {
	font-size: 12px;
	color: #ffffff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 0em;

	width: 70px;
	height: 26px;
	border-radius: 8px;
	opacity: 1;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.tageBox {
	margin-top: 20px;
}
.zl_articleList_pc {
	display: contents;
}
.zl_articleList_app {
	display: none;
}

.chanyeTitle .rsTitle {
	margin-top: 0 !important;
}
.zl_hotCurrent_line {
	margin-left: 39px;
	margin-top: -2px;
	width: 522px;
	height: 1px;
	opacity: 1;
	background: #e5e9fb;
}
.zl_hotCurrent_current {
	position: absolute;
	bottom: 0;
	width: 82px;
	height: 2px;
	opacity: 1;
	display: none;
	/* 主色（一级颜色） */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}
.zl_pc_part .zl_author_articles_main {
	background: transparent;
	padding-top: 26px;
	padding-top: 20px;
	width: 100%;
	height: auto;
	flex-wrap: wrap;
	display: flex;
	align-items: baseline;
	border-radius: 0;
	gap: 0px;
	border-bottom: 1px solid rgba(225, 227, 241, 0.5);
}
.zl_pc_part .zl_author_articles_item {
	width: 50%;
	height: auto;
	opacity: 1;
	flex-grow: 1;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 30px;
}
.zl_author_articles_item_point {
	margin-left: 39px;
	margin-top: 2px;
	width: 6px;
	height: 6px;
	opacity: 1;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 3px;
}
.zl_pc_part .title-box {
	align-items: baseline;
}

.zl_pc_part .zl_author_articles_item_title {
	margin-left: 13px;
	width: auto;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(14px, calc(100vw * 18 / 1440), 18px);
	font-weight: normal;
	line-height: clamp(14px, calc(100vw * 30 / 1440), 30px);
	color: #fff;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.zl_pc_part .zl_author_articles_item_time {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	color: #fff;
	margin-top: 10px;
	margin-left: 58px;
	border-left: 1px solid #fff;
	padding-left: 10px;
}

.release-connent img {
	width: 100%;
}
.zl_pc_part .el-carousel__arrow--left {
	left: 0;
}
.zl_pc_part .el-carousel__arrow--right {
	right: 0;
}
.zl_pc_part .MainLeft .el-carousel__container {
	height: 250px;
}
</style>

<style scoped>
.MainLeft {
	border-right: 1px solid rgba(216, 216, 216, 0.4);
}
.pcPart .banner-herder {
	width: 100%;
	padding-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
	padding-bottom: clamp(10px, calc(100vw * 75 / 1440), 75px);
	box-sizing: border-box;
	background-image: url("https://res.metaera.hk/resources/assets/images/jianbian.png");
	background-size: cover;
	background-position: center;
}

.banner-box {
	margin: 0 auto;
	width: clamp(100px, calc(100vw * 1200 / 1440), 1200px);
	height: clamp(100px, calc(100vw * 400 / 1440), 400px);
}

.pcPart .banner-herder img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 20px;
	overflow: hidden;
}

.content-box {
	padding-top: clamp(40px, calc(100vw * 50 / 1440), 50px);
}

.pcPart .js-FeaturedColumn_container {
	width: 100%;
}
.js-FeaturedColumn_container .js-FeaturedColumn_title {
	height: calc(100vw * 40 / 1440);
	box-sizing: border-box;
	padding-bottom: calc(100vw * 20 / 1440);
	border-bottom: 1px solid rgba(225, 227, 241, 0.5);
}
.js-FeaturedColumn_container
	.js-FeaturedColumn_title
	.js-FeaturedColumn_title-content
	.js-FeaturedColumn_title-info {
	font-size: clamp(20px, calc(100vw * 24 / 1440), 24px);
	line-height: 1;
	font-weight: bold;
	color: #fff;
}
.js-FeaturedColumn_container .js-FeaturedColumn_list {
	width: auto;
	height: auto;
	background: transparent;
	justify-content: flex-start;
	/* border-bottom: 0.5px solid #e5e9fb; */
	padding: 30px 0 0;
	margin: 0 30px;
}
.tageBoxZL {
	margin-top: clamp(40px, calc(100vw * 75 / 1440), 75px);
}
.zl_pc_part .js-FeaturedColumn_item {
	width: 16.6%;
	position: relative;
	padding-bottom: 22px;
	height: 100%;
}
.zl_pc_part .js-FeaturedColumn_item-nickname {
	font-size: clamp(10px, calc(100vw * 14 / 1440), 14px) !important;
	color: #fff !important;
	font-weight: 600;
}
.js-FeaturedColumn_item-articles {
	font-size: clamp(10px, calc(100vw * 13 / 1440), 13px) !important;
	color: #fff !important;
	opacity: 0.5;
}
.zl_pc_part .followMeta {
	background: rgba(255, 255, 255, 0.1);
}
</style>
